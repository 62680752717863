import React from "react"

import AddressEdits from "../../../components/addressEdits";
import Layout from "../../../components/layout";

const AddressEdit = () => {
  return (
      <Layout title="get-offer-no-background">
         <AddressEdits />
      </Layout>
  );
}

export default AddressEdit;