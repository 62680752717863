import React, { useEffect, useState } from "react";
import _isEmpty from "lodash/isEmpty";
import _forEach from 'lodash/forEach';
// import _debounce from 'lodash/debounce';
import { navigate } from "gatsby";

import { addressDisplay, getStorage, setStorage } from "../utils";
import { OFFER_STEP_SECOND_SCREEN } from "../constant";
import InputField from "./common/form/InputField";
import ButtonField from "./common/form/ButtonField";
// import request from "../services/request";

const AddressEdits = () => {
  const [formValue, setFormValue] = useState({})
  const [address, setAddress] = useState({})
  const [addressUnit, setAddressUnit] = useState(null)
  const [addressValue, setAddressValue] = useState(null)
  const [addressValueText, setAddressValueText] = useState(null)
  const [inputWidth, setInputWidth] = useState('')

  useEffect(() => {
    window.addEventListener("resize", displayWindowSize);
    setInputWidth(document.getElementById("pac-input")?.offsetWidth)
    setFormValue(getStorage('formData') ?? {})
    const input = document.getElementById("pac-input");
    if (input && typeof window.google !== "undefined") {
      let map = new window.google.maps.Map(document.getElementById('map'), {
        center: {
          lat: 48,
          lng: 4
        },
        zoom: 4,
        disableDefaultUI: true
      });

      // Create the search box and link it to the UI element.
      document.querySelector('autocomplete-input-container');
      let autocomplete_results_all = document.querySelectorAll('.autocomplete-results');
      let predictValue = []
      _forEach(autocomplete_results_all, autocomplete_results => {
        let service = new window.google.maps.places.AutocompleteService();
        let serviceDetails = new window.google.maps.places.PlacesService(map);
        let displaySuggestions = function (predictions, status) {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            // alert(status);
            // return;
            predictValue = []
          }
          let results_html = [];
          predictValue = predictions
          if (!_isEmpty(predictValue)) {
            predictValue.forEach(function (prediction) {
              results_html.push(`<li class="autocomplete-item" data-type="place" data-place-id=${prediction.place_id}><span class="autocomplete-text">${prediction.description}</span></li>`);
            });
          }
          autocomplete_results.innerHTML = results_html.join("");
          autocomplete_results.style.display = 'block';
          let autocomplete_items = autocomplete_results.querySelectorAll('.autocomplete-item');
          for (let autocomplete_item of autocomplete_items) {
            autocomplete_item.addEventListener('click', function () {
              const selected_text = this.querySelector('.autocomplete-text').textContent;
              const place_id = this.getAttribute('data-place-id');
              let request = {
                placeId: place_id,
                fields: ['name', 'geometry', 'address_components']
              };
              serviceDetails.getDetails(request, function (place, status) {
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                  if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                  } else {
                    if (!_isEmpty(place)) {
                      let obj = {}
                      obj = {
                        city: "",
                        google_county: "",
                        latitude: place.geometry?.location.lat(),
                        longitude: place.geometry?.location.lng(),
                        state: "",
                        street_address: place.name,
                        zip: ""
                      }
                      place.address_components.map((val) => {
                        if (val.types.includes('locality')) {
                          obj = {
                            ...obj,
                            city: val.short_name
                          }
                        }
                        if (val.types.includes('administrative_area_level_2')) {
                          obj = {
                            ...obj,
                            google_county: val.short_name
                          }
                        }
                        if (val.types.includes('administrative_area_level_1')) {
                          obj = {
                            ...obj,
                            state: val.short_name
                          }
                        }
                        if (val.types.includes('postal_code')) {
                          obj = {
                            ...obj,
                            zip: val.short_name
                          }
                        }
                        return obj
                      })
                      const { street_address = " ", city = " ", state = " ", zip = " " } = obj
                      setAddressValue(`${addressDisplay(street_address)} ${addressDisplay(city)} ${addressDisplay(state)} ${zip}`)
                      setAddress(obj)
                    }
                  }
                }
                input.value = selected_text;
                autocomplete_results.style.display = 'none';
              });
            })
          }
        };
        input.addEventListener('input', debounce(function () {
          let value = this.value;
          let pattern = /.*[0-9].*/g
          let testReg = pattern.test(value)
          value.replace('"', '\\"').replace(/^\s+|\s+$/g, '');
          if (value !== "" && testReg) {
            service.getPlacePredictions({
              input: value,
              componentRestrictions: { country: "us" },
              fields: ["address_components", "geometry", "icon", "name"],
            }, displaySuggestions);
          } else {
            autocomplete_results.innerHTML = '';
            autocomplete_results.style.display = 'none';
          }
        }, 150));
      })
    }
    return () => {
      window.removeEventListener("resize", () => { })
    }
  }, [])

  const debounce = (func, wait, immediate) => {
    let timeout;
    return function () {
      let context = this,
        args = arguments;
      let later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  useEffect(() => {
    const { address = "", unit = "" } = formValue
    if (!_isEmpty(formValue)) {
      setAddressUnit(unit)
      setAddressValueText(address)
      setAddressValue(address)
    }
  }, [formValue])

  const onUnitChange = (e, setStateFn) => {
    setStateFn(e.target.value)
  }

  const onContinue = (e) => {
    e.preventDefault();
    let initalValue = getStorage('formData')
    let obj = {
      ...address,
      address: `${addressValue} USA`,
      unit: addressUnit
    }
    setStorage("formData", { ...initalValue, ...obj })

    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "address_submit",
      dtL_address_submit: {
        address: obj.address,
        branch: process.env.GATSBY_BRANCH
      },
    })

    navigate(OFFER_STEP_SECOND_SCREEN)
  }
  const displayWindowSize = () => {
    setInputWidth(document.getElementById("pac-input")?.offsetWidth)
  }
  return (
    <div className="module-edit addressEditContainer">
      <div className="container">
        <div className="form">
          <div className="text-center font-weight-bold title">What's your address?</div>
          <div className="text-center mt-3 mb-3 text-muted addressTitle">You're just minutes away from unlocking your offer!</div>
          <div className="row mt-5">
            <div className="col-12 col-sm-8 form-group required map-input pr-2">
              <InputField id="pac-input" type="text" onChange={(e) => onUnitChange(e, setAddressValueText)} placeholder="Your Address" className="form-control rounded-0 input" autoComplete="off" value={addressValueText === null ? '' : addressValueText} />
              <ul className="autocomplete-results" style={{ width: `${inputWidth}px` }} />
            </div>
            <div className="col-12 col-sm-4 form-group pl-2 unit-input">
              <InputField placeholder="Unit" className="form-control rounded-0 input" onChange={(e) => onUnitChange(e, setAddressUnit)} value={addressUnit === null ? '' : addressUnit} />
            </div>
          </div>
          <div className="row mt-2 buttons">
            <div className="col-6 pr-2">
              <ButtonField className="btn-outline-secondary btn-lg btn-block btnText" name="Back" onClick={() => navigate(-1)} />
            </div>
            <div className="col-6 pl-2">
              <ButtonField className="text-white btn-lg btn-block bgYellow btnText" name="Continue" onClick={onContinue} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddressEdits;